<template>
  <div class="page-content">
    <div class="mx-1">
    </div>
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-10 col-sm-10">
                <h3>Backoffice Users</h3>
              </div>
              <div class="col-2 col-sm-2">
                <button v-if="checkPermission.create" class="btn btn-primary btn-block" @click="addUser">Add User</button>
              </div>
            </div>
            <div class="row d-flex justify-content-between align-items-center pt-3">
              <div class="col-12 col-sm-12">
                <form class="search-form" @submit.prevent="doSearch()">
                  <div class="input-group border rounded-sm">
                    <div class="input-group-prepend">
                      <div class="input-group-text border-0 rounded-sm">
                        <FeatherIcon type="search" class="icon-md cursor-pointer" />
                      </div>
                    </div>
                    <input v-model="search.search" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here...">
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <!-- <th>Role</th> -->
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Added Date</th>
                    <th>Last Login</th>
                    <th colspan="4">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in usersList" :key="item.id">
                    <td>{{ item.full_name }}</td>
                    <!-- <tf>{{ item.role_backoffices.name }}</tf> -->
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone_number }}</td>
                    <td>{{ item.created_at_str }}</td>
                    <td>{{ item.last_login_at_str }}</td>
                    <td>
                      <button v-if="checkPermission.update" class="btn btn-outline-primary btn-sm mr-2" @click="editUser(item)">
                        <font-awesome icon="user-edit"/>
                      </button>
                      <button v-if="checkPermission.delete" class="btn btn-outline-primary btn-sm" @click="deleteUser(item.id)">
                        <font-awesome icon="times"/>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>

      <el-pagination
      :page-size="10"
      layout="prev, pager, next"
      :total="this.totalRow"
      @current-change="setPage"
      >

      </el-pagination>
    </div>

    <!-- <b-modal v-model="showModal" title="Add New User" hide-footer>
      <b-form @submit.prevent="createUser" @reset="closeModal">
        <b-form-group id="ig-user-name" label="Name:" label-for="i-user-name">
          <b-form-input id="i-user-name" type="text" placeholder="Input your old password" required
            v-model="userForm.full_name" />
        </b-form-group>
      </b-form>
    </b-modal> -->
    <b-modal v-model="showModal" title="Add New User" hide-footer>
      <b-form @submit.prevent="createUser" @reset="closeModal">
        <b-form-group id="ig-user-name" label="Full Name:" label-for="i-user-name">
          <b-form-input :placeholder="$t('userBackoffice.placeholder.full_name')" autocomplete="off" id="i-user-name" type="text" v-model="v$.userForm.full_name.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.full_name.$invalid" v-if="v$.userForm.full_name.$errors[0]">
            {{ $t('validation.' + v$.userForm.full_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-user-email" label="Email:" label-for="i-user-email">
          <b-form-input :placeholder="$t('userBackoffice.placeholder.email')" autocomplete="one-time-password" id="i-user-email" type="text" v-model="v$.userForm.email.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.email.$invalid" v-if="v$.userForm.email.$errors[0]">
            {{ $t('validation.' + v$.userForm.email.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-phone-number" label="Phone Number:" label-for="i-phone-number">
          <b-form-input :placeholder="$t('userBackoffice.placeholder.phone_number')" autocomplete="one-time-password" id="i-phone-number" type="text" v-model="v$.userForm.phone_number.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.phone_number.$invalid" v-if="v$.userForm.phone_number.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.phone_number.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.phone_number.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.phone_number.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-role" label="Role:" label-for="i-role">
          <el-select class="w-100" id="i-role" v-model="v$.userForm.role_id.$model">
            <el-option v-for="roles in roleList" :key="roles.id" :label="roles.name" :value="roles.id"></el-option>
          </el-select>
          <b-form-invalid-feedback :state="!v$.userForm.role_id.$invalid" v-if="v$.userForm.role_id.$errors[0]">
            {{ $t('validation.' + v$.userForm.role_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-password" label="Password:" label-for="i-passwordl">
          <b-form-input :placeholder="$t('userBackoffice.placeholder.password')" autocomplete="one-time-code" id="i-passwordl" type="password" v-model="v$.userForm.password.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.password.$invalid" v-if="v$.userForm.password.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.password.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.password.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showModalEdit" title="Edit User" hide-footer>
      <b-form @submit.prevent="saveEdit" @reset="closeModal">
        <b-form-group id="ig-user-name" label="Full Name:" label-for="i-user-name">
          <b-form-input id="i-user-name" type="text" v-model="userForm.full_name" />
          <b-form-invalid-feedback :state="!v$.userForm.full_name.$invalid" v-if="v$.userForm.full_name.$errors[0]">
            {{ $t('validation.' + v$.userForm.full_name.$errors[0].$validator) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!v$.userForm.full_name.$invalid" v-else></b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-user-email" label="Email:" label-for="i-user-email">
          <b-form-input id="i-user-email" type="email" v-model="userForm.email" />
          <b-form-invalid-feedback :state="!v$.userForm.email.$invalid" v-if="v$.userForm.email.$errors[0]">
            {{ $t('validation.' + v$.userForm.email.$errors[0].$validator) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!v$.userForm.email.$invalid" v-else></b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-phone-number" label="Phone Number:" label-for="i-phone-number">
          <b-form-input id="i-phone-number" type="tel" v-model="userForm.phone_number" />
          <b-form-invalid-feedback :state="!v$.userForm.phone_number.$invalid" v-if="v$.userForm.phone_number.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.phone_number.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.phone_number.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.phone_number.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!v$.userForm.phone_number.$invalid" v-else></b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-role" label="Role:" label-for="i-role">
          <el-select class="w-100" id="i-role" v-model="userForm.role_id">
            <el-option v-for="roles in roleList" :key="roles.id" :label="roles.name" :value="roles.id"></el-option>
          </el-select>
          <b-form-invalid-feedback :state="!v$.userForm.role_id.$invalid" v-if="v$.userForm.role_id.$errors[0]">
            {{ $t('validation.' + v$.userForm.role_id.$errors[0].$validator) }}
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!v$.userForm.role_id.$invalid" v-else></b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="ig-password" label="Password:" label-for="i-passwordl">
          <b-form-input :placeholder="$t('users.placeholder.password')" autocomplete="one-time-code" id="i-password" type="password" v-model="v$.userForm.password.$model" />
          <b-form-invalid-feedback :state="!v$.userForm.password.$invalid" v-if="v$.userForm.password.$errors[0]">
            <span v-if="!['maxLength', 'minLength'].includes(v$.userForm.password.$errors[0].$validator)">{{ $t('validation.' + v$.userForm.password.$errors[0].$validator) }}</span>
            <span v-else>{{ v$.userForm.password.$errors[0].$message }}</span>
          </b-form-invalid-feedback>
          <b-form-invalid-feedback :state="!v$.userForm.password.$invalid" v-else></b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex flex-row-reverse">
          <b-button type="submit" variant="primary" class="ml-2">{{ $t('general.submit') }}</b-button>
          <b-button type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<style>
#btn_link {
  background:none;border: none;color: #1e5b2a;
}
#btn_link:hover {
  text-decoration: underline;
  color: #143d1c;
}
.btn-search {
  margin-left: 1em;
}
.pageBox {
  background-color: #1e5b2a;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em 1em;
  cursor: pointer;
  border: 0;
}
.pageBox:hover {
  color: white;
}
</style>
<script>
import moment from 'moment';
// import { isEqual } from 'lodash';
import useVuelidate from '@vuelidate/core';
import {
  required, email, numeric, minLength, maxLength, requiredIf,
} from '@vuelidate/validators';

import usersAPI from '../../api/userBackoffice';
import avatar from '../../library/avatar';
import RSA from '../../library/rsa-encrypt';
import popupErrorMessages from '../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
  },
  name: 'BackofficeUsers',
  metaInfo: {
    title: 'User Backoffice',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      userForm: {
        full_name: {
          required,
        },
        phone_number: {
          required,
          numeric,
          minLength: minLength(9),
          maxLength: maxLength(15),
        },
        email: {
          required,
          email,
        },
        role_id: {
          required,
        },
        password: {
          required: requiredIf(this.action === 'add'),
          minLength: minLength(8),
        },
      },
    };
  },
  data() {
    return {
      loading: {
        table: false,
      },
      usersList: [],
      roleList: [],
      showModal: false,
      showModalEdit: false,
      search: {
        search: this.$route.query.search || '',
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
      },
      page: 1,
      totalRow: 0,
      editedId: '',
      action: 'add',
      userForm: {
        full_name: '',
        phone_number: '',
        email: '',
        role_id: '',
        password: '',
      },
    };
  },
  computed: {
    // page() {
    //   if (this.$route.query.page) {
    //     return this.$route.query.page;
    //   }
    //   return 1;
    // },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getUserList();
      },
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
  },
  mounted() {
    this.getUserList();
    this.getRoles();
  },
  methods: {
    doSearch() {
      this.page = 1;
      this.search.page = 1;
      this.search.per_page = 10;
      this.getUserList();
    },
    setPage(val) {
      this.search.page = val;
      this.page = val;
      this.getUserList();
    },
    async getUserList() {
      // const options = {
      //   page: this.search.page.toString(),
      //   per_page: this.search.per_page.toString(),
      //   search: this.search.search,
      // };
      // if (!isEqual(options, this.$route.query)) {
      //   this.$router.replace({ query: options });
      // }
      const loader = this.$loading.show();
      this.loading.table = true;
      await usersAPI.getList(this.search.page, this.search.search, this.company_id)
        .then((res) => {
          this.totalRow = res.data.count;
          this.usersList = res.data.rows.map((user) => {
            const data = user;
            data.image = avatar(user.full_name, user.email);
            ['created_at', 'last_login_at'].forEach((v) => {
              data[`${v}_str`] = user[v] ? moment(user[v]).format('DD MMM YYYY, HH:mm') : '-';
            });
            return data;
          });
        });
      this.loading.table = false;
      loader.hide();
    },
    async getRoles() {
      await usersAPI.getRoles()
        .then(async (res) => {
          this.roleList = res.data;
        });
    },
    clearUserForm() {
      this.userForm = {
        full_name: '',
        phone_number: '',
        email: '',
        role_id: '',
        password: '',
      };
    },
    closeModal() {
      this.editedId = '';
      this.showModal = false;
      this.showModalEdit = false;
      this.v$.userForm.$reset();
      this.clearUserForm();
    },
    addUser() {
      this.action = 'add';
      this.clearUserForm();
      this.showModal = true;
      this.v$.userForm.$touch();
    },
    async createUser() {
      this.v$.userForm.$touch();
      if (this.v$.userForm.$error) return;
      const loader = this.$loading.show();
      await usersAPI.createUser(this.userForm)
        .then(async (response) => {
          console.log(this.userForm);
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('userBackoffice.success.add'),
            type: 'success',
          });
          this.page = 1;
          this.search = {
            search: this.$route.query.search || '',
            page: this.$route.query.page || 1,
            per_page: this.$route.query.per_page || 10,
          };
          this.getUserList();
          this.closeModal();
        }).catch((err) => { console.log(err); });
      loader.hide();
    },
    editUser(user) {
      this.action = 'edit';
      this.v$.userForm.$touch();
      this.editedId = user.id;

      this.userForm.full_name = user.full_name;
      this.userForm.phone_number = user.phone_number;
      this.userForm.email = user.email;
      this.userForm.role_id = user.role_id;
      this.userForm.password = '';

      this.showModalEdit = true;
    },
    async saveEdit() {
      this.v$.userForm.$touch();
      if (this.v$.userForm.$error) return;
      const loader = this.$loading.show();
      if (this.userForm.password) {
        this.userForm.password = RSA.encrypt(this.userForm.password, 'base64');
      }
      await usersAPI.update(this.editedId, this.userForm)
        .then(async (response) => {
          loader.hide();
          await popupErrorMessages(response);
          this.$message({
            message: this.$t('userBackoffice.success.edit'),
            type: 'success',
          });
          this.page = 1;
          this.search = {
            search: this.$route.query.search || '',
            page: this.$route.query.page || 1,
            per_page: this.$route.query.per_page || 10,
          };
          this.getUserList();
          this.closeModal();
        });
    },
    async deleteUser(id) {
      this.$confirm(this.$t('userBackoffice.confirm.delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await usersAPI.delete(id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('userBackoffice.success.delete'),
                  type: 'success',
                });
                this.page = 1;
                this.search = {
                  search: this.$route.query.search || '',
                  page: this.$route.query.page || 1,
                  per_page: this.$route.query.per_page || 10,
                };
                this.getUserList();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
  },
};
</script>
